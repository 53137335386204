<template>
  <div class="home">
    <div class="base-carousel">
      <div
        style="`position:static;width:100%;min-height:200px;display:flex;flex:1 1 auto;flex-wrap:wrap;justify-content:center;
                align-items:center;`"
        class="wrapper-carousel"
      >
        <div
          style="display:block;padding:28px;flex: 0 0 66.667%; width:66.667%; margin:0;"
        >
          <splide :options="splideOptions" @splide:move="cambio">
            <splide-slide v-for="(elem, index) in slogans" :key="index">
              <img src="../assets/landing.png" />
              <div class="slogan" :ref="`item${index}`">
                <Deslizar
                  :name="'deslizarOcultar'"
                  :ocultar="true"
                  :posInicial="{ x: '-150px', y: '0' }"
                  :posFinal="{ x: '50px', y: '0' }"
                  :duracion="1300"
                >
                  <p v-show="visible[index]">{{ elem }}</p>
                </Deslizar>
              </div>
            </splide-slide>
          </splide>

          <!-- <VueSlickCarousel :dots="true" :arrows="true" @beforeChange="cambio">
            <div v-for="(elem, index) in slogans" :key="index" class="banner">
              <img src="../assets/landing.png" />
              <div class="slogan" :ref="`item${index}`">
                <Deslizar
                  :name="'deslizarOcultar'"
                  :ocultar="true"
                  :posInicial="{ x: '-150px', y: '0' }"
                  :posFinal="{ x: '0', y: '0' }"
                  :duracion="1300"
                >
                  <p v-show="visible[index]">{{ elem }}</p>
                </Deslizar>
              </div>
            </div>
          </VueSlickCarousel> -->
        </div>
      </div>
    </div>
    <!-- <div class="banner" >
      <img src="../assets/landing.png" />
      <div class="slogan" ref="item0">
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '-150px', y: '0' }"
          :posFinal="{ x: '0', y: '0' }"
        >
          <p v-show="visible[0]">
            Servicios de CNC, Manufacturas Tier 2, Doblado de varilla, tubo y
            cable, Fixtures y herramientas, 3 generaciones de experiencia a su
            servicio.
          </p>
        </Deslizar>
      </div>
    </div> -->
    <div class="contenedor-peq">
      <p style="max-width:80%">
        En Ingenieria y Manufactura Sigue S.A de C.V tenemos más de 40 años en
        el mercado y 3 generaciones de experiencia de servicio, enfocandonos
        principalmente en la manufactura de piezas metal mecánicas.
      </p>
    </div>

    <h2>Calidad</h2>
    <div class="temp" v-show="!visible[visible.length - 1]" />
    <div class="contenedor-peq" ref="calidad">
      <Deslizar
        :name="'deslizarOcultar'"
        :ocultar="true"
        :posInicial="{ x: '-20px', y: '0' }"
        :posFinal="{ x: '0', y: '0' }"
        :duracion="700"
      >
        <img
          src="../assets/certificadoCalidad.png"
          class="contenedor-item"
          v-show="visible[visible.length - 1]"
        />
      </Deslizar>
      <Deslizar
        :name="'deslizarOcultar'"
        :ocultar="true"
        :posInicial="{ x: '100px', y: '0' }"
        :posFinal="{ x: '0', y: '0' }"
      >
        <p class="contenedor-item" v-show="visible[visible.length - 1]">
          Nuestras soluciones son elaboradas de acuerdo al estandar ISO
          9001:2015 en el cual estamos certificados, nuestros clientes reciben
          un servicio confiable y de alta calidad.
        </p>
      </Deslizar>
    </div>
    <!-- <FormContacto /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import FormContacto from "@/components/FormContacto.vue";
import Deslizar from "@/components/Deslizar.vue";
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

export default {
  name: "Home",
  components: {
    // FormContacto
    Deslizar,
    Splide,
    SplideSlide
    // VueSlickCarousel
  },
  data() {
    return {
      animar: true,
      observer: {},
      visible: [],
      slogans: [
        "Servicios de CNC",
        " Manufacturas Tier 2",
        "Doblado de varilla, tubo y cable",
        "Fixtures y herramientas"
      ],
      xyMoviles: { x: "-150px", y: "20px" },
      sliderWidthMoviles: "625px",
      splideOptions: {
        type: "loop",
        perPage: 1,
        gap: "1rem",
        autoplay: true
      }
    };
  },
  methods: {
    /* eslint no-unused-vars: 0 */
    cambio(dummy, anterior, siguiente, dummy2) {
      this.visible.splice(siguiente, 1, false);
      this.visible.splice(anterior, 1, true);
    }
    /* eslint no-unused-vars: 1 */
  },
  mounted() {
    // let items = Array(this.$refs.item0, this.$refs.item1);
    let items = Array(
      this.$refs.item0[0],
      this.$refs.item1[0],
      this.$refs.item2[0],
      this.$refs.item3[0],
      this.$refs.calidad
    );
    // let items = Array(this.$refs.item0, this.$refs.item3);

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio >= 0.5) {
            this.observer.unobserve(entry.target);
            let indice = entry.target.getAttribute("idref");
            this.visible.splice(indice, 1, true);
          }
        });
      },
      { threshold: 0 }
    );

    items.forEach((el, index) => {
      this.observer.observe(el);
      el.setAttribute("idref", index);
      this.visible[index] = false;
    });
    this.visible.splice(0, 1, true);
  },
  beforeDestroy() {
    this.observer.disconnect();
  }
};
</script>
<style lang="stylus" scoped>
.home
  display flex
  flex-flow column nowrap
  justify-content center
  align-items center
.logo-temp
  width  300px
  height auto
  @media (min-width: $md)
    width 369px
  @media (min-width: $lg)
    width 450px
  @media (min-width: $xl)
    width 500px
.banner
  display flex
  position relative
  margin auto
  // top 0
  // left 0
  > img
    min-height 100px
    min-width 100px
    max-width 100vw
    max-height auto
    z-index 1
  @media screen and (min-width: $sm)
    margin-top 2em
    max-width 100%
.slogan
  position absolute
  display flex
  justify-content center
  margin 0
  left 0
  bottom 50px
  width 60%
  // min-height 10px
  color white
  z-index 2
  > p
    word-wrap: break-word
    padding .5rem
    background-color rgba(125,125,125,.5)
.animar
  &-enter-to, &-leave-active
    transform translate(0,0)
    opacity 1
    transition transform .9s ease-in, opacity .9s ease-in
  &-enter
    transform translate(-200px, 0)
    opacity 0
    transition transform .9s ease-in, opacity .9s ease
  &-leave-to
    opacity 0
    transition opacity .9s ease
.temp
  width 300px
  height 100px
  margin 1rem
.base-carousel
  max-width:500px
  @media screen and (min-width: $sm)
    max-width: 900px
  @media screen and (min-width: $md)
    max-width:1319px
</style>

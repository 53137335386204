var render, staticRenderFns
import script from "./Deslizar.vue?vue&type=script&lang=js&"
export * from "./Deslizar.vue?vue&type=script&lang=js&"
import style0 from "./Deslizar.vue?vue&type=style&index=0&id=099a4365&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "099a4365",
  null
  
)

export default component.exports
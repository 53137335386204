<script>
export default {
  functional: true,
  name: "Deslizar",
  props: {
    name: { type: String, required: true },
    mode: { type: String, default: "out-in" },
    appear: { type: Boolean, default: true },
    css: { type: Boolean, default: false },
    ocultar: { type: Boolean, default: false },
    posInicial: {
      type: Object,
      default: () => {
        return { x: "-100px", y: "0" };
      }
    },
    posFinal: {
      type: Object,
      default: () => {
        return { x: "0", y: "0" };
      }
    },
    duracion: { type: Number, default: 1000 },
    funcion: { type: String, default: "ease" }
  },
  render(createElement, context) {
    let data = {
      props: context.props,
      on: {
        beforeEnter(el) {
          requestAnimationFrame(() => {
            if (context.props.ocultar) el.style.opacity = 0;
            else el.style.opacity = 1;
            // el.style.transform = `translate(-100px,0)`;
            el.style.transform = `translate(${context.props.posInicial.x}, ${context.props.posInicial.y})`;
          });
        },
        enter(el, done) {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              // el.style.transform = `translate(0, 0)`;
              el.style.transform = `translate(${context.props.posFinal.x}, ${context.props.posFinal.y})`;

              if (context.props.ocultar) {
                el.style.opacity = 1;
                el.style.transition = `transform ${context.props.duracion}ms ${context.props.funcion}, opacity ${context.props.duracion}ms ${context.props.funcion}`;
              } else {
                el.style.transition = `transform ${context.props.duracion}ms ${context.props.funcion}`;
              }
              done();
            });
          });
        },
        leave(el, done) {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              // el.style.transform = `translate(-100px, 0)`;
              el.style.transform = `translate(${context.props.posInicial.x}, ${context.props.posInicial.y})`;

              if (context.props.ocultar) {
                el.style.opacity = 0;
                el.style.transition = `transform ${context.props.duracion}ms ${context.props.funcion}, opacity ${context.props.duracion}ms ${context.props.funcion}`;
              } else {
                el.style.transition = `transform ${context.props.duracion}ms ${context.props.funcion}`;
              }
              done();
            });
          });
        },
        afterLeave(el) {
          el.style.display = "block";
        }
      }
    };
    return createElement("transition", data, context.children);
  }
};
</script>

<style lang="stylus" scoped>
.deslizar
  &-izq
    &-leave, &-enter-to
        transform translate(0)
        transition transform .3s ease
    &-enter, &-leave-to
        transform translate(100%)
        transition transform .3s ease-in
  &-der
    // &-active
    &-leave, &-enter-to
        transform translate(0)
        transition transform .3s ease
    &-enter, &-leave-to
        transform translate(-100%)
        transition transform .3s ease-in
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/nosotros",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/servicios",
    name: "Servicios",
    component: () =>
      import(/* webpackChunkName: "servicios" */ "../views/Servicios.vue")
  },
  {
    path: "/maquinaria",
    name: "Maquinaria",
    component: () =>
      import(/* webpackChunkName: "maquinaria" */ "../views/Maquinaria.vue")
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: () =>
      import(/* webpackChunkName: "contacto" */ "../views/Contacto.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "menu-item-activo",
  routes,
  /* eslint no-unused-vars: 0 */
  scrollBehavior(to, from, savedPosition) {
    if (window.innerWidth >= 768 || to.fullPath === "/") return { x: 0, y: 0 };
    else return { selector: "h1", offset: { x: 0, y: 55 } };
  }
  /* eslint no-unused-vars: 1 */
});

export default router;

<template>
  <div id="app">
    <template v-if="esMovil">
      <div class="header-botones">
        <a class="btn-contacto" href="#" @click="controlFormContacto(true)"
          >Contáctanos</a
        >
        <div class="icon-menu btn-menu" @click="controlMenu(!verMenu)" />
      </div>
      <div
        :class="['bg-menu', verMenu ? 'bg-menu-active' : 'bg-menu-leave']"
      ></div>
      <div
        :class="[
          'contenedor-form',
          verFormContacto ? 'contenedor-form-active' : 'contenedor-form-leave'
        ]"
      >
        <FormContacto />
      </div>
    </template>
    <div class="datos-contacto" v-else>
      <div class="datos-contacto-item">
        
      </div>
      <div class="datos-contacto-item">
        
      </div>
      <div class="datos-contacto-item">
        
      </div>
    </div>
    <div id="nav">
      <div class="logo">
        <router-link
          to="/"
          :src="require(`./assets/logo-sigue transparente.png`)"
          tag="img"
          height="150px"
          style="cursor: pointer;"
          alt="Ingenieria y Manufactura Sigue"
        />
      </div>
      <img src="./assets/logo-iso.jpeg" alt="iso 9001" height="100px" />
      <transition name="desdoblar">
        <div class="nav-grupo-enlaces" v-show="mostrarMenu">
          <router-link
            to="/"
            exact
            class="menu-item"
            @click.native="controlMenu(false)"
            >Inicio</router-link
          >
          <router-link
            to="/nosotros"
            exact
            class="menu-item"
            @click.native="controlMenu(false)"
            >Nosotros</router-link
          >
          <router-link
            to="/servicios"
            exact
            class="menu-item"
            @click.native="controlMenu(false)"
            >Servicios</router-link
          >
          <router-link
            to="/maquinaria"
            exact
            class="menu-item"
            @click.native="controlMenu(false)"
            >Maquinaria</router-link
          >
        </div>
      </transition>
      <!-- <a class="btn-contacto" href="/#contacto" v-if="!esMovil">Contáctanos</a> -->

      <router-link
        :class="'btn-contacto'"
        to="/contacto"
        active-class=""
        exact-active-class=""
        v-if="!esMovil"
        >Contáctanos</router-link
      >
      <template v-else>
        <div class="datos-contacto-item grid-item">
          <span class="icon-location"
            >Apodaca, N.L.
          </span>
        </div>
        <div class="datos-contacto-item grid-item">
          
        </div>
        <div class="datos-contacto-item grid-item">
          
        </div>
        <div class="datos-contacto-item grid-item">
          
        </div>
        <div class="datos-contacto-item grid-item">
          
        </div>
      </template>
    </div>
    <router-view />

    <div class="datos-contacto">
      <template v-if="!esMovil">
        <div class="datos-contacto-item">
          <span class="icon-location"
            >Apodaca, N.L.
          </span>
          
        </div>
      </template>
      <div class="datos-contacto-item">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FormContacto: () => import("@/components/FormContacto.vue")
  },
  data() {
    return {
      verMenu: false,
      verFormContacto: false,
      innerWidth: window.innerWidth
    };
  },
  computed: {
    esMovil() {
      return this.innerWidth < 768;
    },
    mostrarMenu() {
      return (this.verMenu && this.esMovil) || !this.esMovil;
    }
  },
  methods: {
    controlMenu(val) {
      this.verFormContacto = false;
      this.verMenu = val;
    },
    controlFormContacto(val) {
      this.verMenu = false;
      this.verFormContacto = val;
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  }
};
</script>

<style lang="stylus">
deslizar(x,y, duracion, funcion)
  transform translate(x, 0)
  transition transform duracion funcion
*,
*:before,
*:after {
    box-sizing: inherit;
}
html {
    box-sizing: border-box;
    overflow-x hidden
    width 100vw
}
.menu-item
  text-decoration none
  margin 0 .5rem
  border-radius 3px
  padding 4px
  color white
  // width 100vw
  margin 1em 0
  &::after
    content ""
    width 100%
    height 3px
    position absolute
    transform scaleX(0)
  &:hover
    // background-color $primario
    // color white
    color $primario
    position relative
    &::after
      content ""
      width 100%
      height 3px
      background-color $primario
      position absolute
      z-index 100
      left 4px
      right 4px
      bottom -4px
      transition transform ease 200ms
      transform scaleX(1)
  @media screen and (min-width: $sm)
    color #2c3e50
    margin 0 .5rem
    // width auto
    // margin 0
.menu-item-activo
  // background-color $primario
  // color white
  color $primario
  position relative
  &::after
    content ""
    width 100%
    height 3px
    background-color $primario
    position absolute
    z-index 100
    left 4px
    right 4px
    bottom -4px
    transform scaleX(1)
h1, h3, div
  margin 0
body
  background-color #f0f0f0
  margin 0
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  display flex
  flex-flow column nowrap
  align-items center
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  font-size 18px
  color #2c3e50
  // margin-top 60px
  @media screen and (min-width: $lg)
    font-size 20px
  @media screen and (min-width: $xl)
    font-size 24px
#nav
  // display flex
  // flex-flow column wrap
  // justify-content flex-start
  // align-items center
  @media screen and (max-width: 767px)
    display grid
    grid-auto-flow column
    grid-template-rows repeat(5,1fr)
    grid-template-columns 1fr 1fr
    background-color rgb(200,200,200);
    align-items center
    justify-items center
    width 100%
    height 100vh
    padding-bottom 1em
    > img
      grid-row 3 / span 3
    .grid-item
      display flex
      flex-flow column nowrap
      justify-self flex-start
      padding-right 1em
      font-size .75em
      text-align left
      &:nth-child(5), &:last-child
        align-self flex-start
      &:nth-child(6)
        align-self flex-end
      &:nth-child(7)
        align-self center
  @media screen and (min-width: $sm)
    background-color transparent;
    display flex
    flex-flow row wrap
    justify-content center
    align-items center
    width 100%
    height auto
.logo
  margin 0
  grid-row 1 / span 2
  // display flex
  // margin-left .5em
  // align-self center
  @media screen and (min-width: $sm)
    margin-left 0
    margin-right 2em
  @media screen and (min-width: $md)
    margin-left 0
    margin-right 75px
.datos-contacto
  display flex
  flex-flow row wrap
  justify-content center
  width 100%
  padding 1em 0
  font-size .75em
  background-color rgba(45, 45, 45, .2)
  @media screen and (min-width: $sm)
    font-size .6em
  span
    margin 0 .5rem
.datos-contacto-item
  display flex
  flex-flow column nowrap
  align-items flex-start
  justify-content flex-start
  margin 0
  @media screen and (min-width: $sm)
    margin 0 2em
.header-botones
  display flex
  flex-flow row nowrap
  justify-content space-between
  align-items center
  position sticky
  top 0px
  background-color lighten($secundario, 4)
  // background linear-gradient(40deg, $secundario, rgb(180,180,180))
  width 100vw
  z-index 101
.btn-contacto
  text-decoration none
  color white
  cursor default
  border solid 3px $secundario
  padding .9rem .5rem
  font-size 1.05rem
  font-weight: 600
  border-radius 5px
  z-index 100
  background-color $secundario
  transition: background-color .2s ease-in,
            color .4s ease-out
  &:hover
    background-color: $primario
    color: $secundario
  @media screen and (min-width: $sm)
    position static
    margin-left 1em
  @media screen and (min-width: $md)
    position static
    margin-left 8vw
.btn-menu
  display block
  // position sticky
  // top .25em
  // right 2em
  margin-right 1em
  z-index 100
  color white
  @media screen and (min-width: $sm)
    display none
.bg-menu
  display block
  position fixed
  width 100vw
  height 100%
  background-color rgb(50,50,50)
  z-index 50
  &-active
    deslizar(0, 2em, .2s, ease-out)
  &-leave
    deslizar(100%, 2em, .3s, ease-in)
.nav-grupo-enlaces
  display flex
  flex-flow column nowrap
  position fixed
  top 55%
  left 50%
  transform translate(-50%,-50%)
  z-index 100
  @media screen and (min-width: $sm)
    display flex
    // visibility visible
    // opacity 1
    flex-flow row nowrap
    margin-left 100px
    position static
    transform translate(0,0)
.desdoblar-enter-active
  animation desdoble .3s ease-in
.desdoblar-leave-active
  animation desdoble .2s reverse ease-out
@keyframes desdoble
  from
    opacity 0
  to
    opacity 1
.escalar-enter-active
  // transform scale(.25)
  // transition transform .5s ease-in
  animation escala .5s ease-in
@keyframes escala
  from
    transform scale(.25)
  to
    transform scale(1)
.contenedor
  display flex
  flex-flow column nowrap
  justify-content center
  align-items center
  margin auto
  @media screen and (min-width: $sm)
    flex-flow row wrap
    align-items flex-start
.contenedor-peq
  display flex
  flex-flow column nowrap
  justify-content center
  align-items center
  margin auto
  max-width 60vw
  min-width 400px
  @media screen and (min-width: $md)
    flex-flow row nowrap
    align-items flex-start
.contenedor-item
  max-width 80%
  margin 1rem 1rem
  @media screen and (min-width: $sm)
    max-width 100%
  @media screen and (min-width: $md)
    max-width 40%
.contenedor-item-peq
  max-width 100%
  text-align left
  margin 0 1rem
  @media screen and (min-width: $md)
    max-width 30%
.contenedor-form
  display flex
  justify-content center
  align-items center
  position fixed
  width 100%
  height 100vh
  background-color darken($secundario,1)
  z-index 25
  &-active
    deslizar(0, 0, .3s, ease-in)
  &-leave
    deslizar(-100%, 0,.3s, ease-out)
@font-face
  font-family: 'fontello'
  src url('assets/font/fontello.woff2') format('woff2'),
      url('assets/font/fontello.woff') format('woff')
  font-weight normal
  font-style normal


 [class^="icon-"]:before, [class*=" icon-"]:before
  font-family "fontello"
  font-style normal
  font-weight normal

  display inline-block
  text-decoration inherit
  width 1em
  margin-right .2em
  text-align center
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant normal
  text-transform none

  /* fix buttons height, for twitter bootstrap */
  line-height 1em

  /* you can be more comfortable with increased icons size */
  // font-size: 1.6rem

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.icon-mail:before
  content '\e800' /* '' */
.icon-phone:before
  content '\e801' /* '' */
.icon-menu:before
  content: '\f0c9' /* '' */
  font-size 1.6em
.icon-copyright:before
  content '\f1f9' /* '' */
.icon-clock:before
  content '\E802' /* '' */
.icon-location:before
  content '\E803' /* '' */
</style>
